import React, { useState } from "react";
import "./TermsPage.scss";

const TermsPage = () => {

  return (
    <div className="terms">
      <div className="terms_content">
        <h2>Terms and Conditions</h2>
        <div className="terms_content_textContent">
          <article>
            <p>
              These terms and conditions ('Terms') apply to your use of our mobile application ('App') and our services described below and form part of the agreement between you and Aircrex Pty Ltd trading as Hello Clever (’Clever’, 'we', 'us').
              <br></br>
              By using the Clever App you agree to these Terms. You should read these Terms carefully and only use our service if you are over 14 and you agree to them.
              <br></br>
              These Terms have no fixed or minimum duration. They will apply until either you or we end this agreement (see 'Your right to cancel and end this agreement' and 'Our right to suspend, restrict or end this agreement')
            </p>
          </article>
          <article>
            <h3>
              What is Clever?
            </h3>
            <p>
              Clever is the most comprehensive, independent financial management app in Australia.
              <br></br>
              We recognise everyone will want something different from Clever – so that’s why Clever has been built to learn from each user, so we can provide you with a customised experience that will be the most help for you.
            </p>
          </article>
          <article>
            <h3>
              How does Clever do it?
            </h3>
            <p>
              Through the secure processing of your personal data. Your privacy and security are our number one priority, and your data is always protected by bank-level security.
              <br></br>
              Without access to your personal data, and the right to process it as required, Clever cannot perform the function for you that it has been designed for.
              <br></br>
              Clever is independent, we are not endorsed or sponsored by the providers of your personal accounts.
              <br></br>
              As part of the service, Clever may provide content, website links and offers to you from third parties. We will do our best to check accuracy, but we aren’t responsible for the products and services provided to you by these third parties – these fall under the terms and conditions of the third party themselves.</p>
          </article>
          <article>
            <h3>
              What does Clever cost?
            </h3>
            <p>
              Clever is free to use. There is no subscription charge or price that you are required to pay, unless you choose to do so. But so we can continue as a business we may receive payments if you choose to use or purchase third party products and services that we promote. The suggestions that Clever makes are not driven by whether we receive a payment or not - we do not compromise ourselves in that way, we always look for the best fit and service for the users of Clever.
              <br></br>
              As you would expect, these Terms only cover the services provided by us. They do not deal with taxes or costs that might be charged by third parties that you need to pay in order to receive our services – for example costs charged by your mobile phone provider.</p>
          </article>
          <article>
            <h3>
              Using Clever
            </h3>
            <p>
              To use Clever you need to download the Clever App and register to create a Clever account in the App. You can only do this on one device.
              <br></br>
              If you decide not to connect a bank account straight away, you will still be able to use ‘Clever’, but a user is provided with a richer experience and personalised insights if they do.
              <br></br>
              Your use of the Clever App is also subject to the terms and conditions and privacy policies of the app store you downloaded it from, but if there is a conflict between their and our terms and conditions, ours take priority.
              <br></br>
              Some parts of the Clever service depend on other people (third parties and partners). We always try to keep Clever available, though we can't promise that it will always be so or will work perfectly if the Clever App or a partner service is undergoing maintenance. If this happens, we’ll tell you about it if we think you need to know and will get things back up and running as soon as we can.
              <br></br>
              For the Clever App to work, your device needs to meet our operating system requirements. If we know your device’s operating system and we plan to stop supporting it, we’ll give you at least 30 days’ notice. If you don’t install operating system updates and new versions of the App, the App might not work properly or at all.
              <br></br>
              We ask that you do not attempt to try to access, adapt, reproduce, duplicate, copy, sell, trade, resell or harm any part of the Clever App in any way, try to collect data in the service or in transmission between our systems, or use the service unlawfully. Also you won’t (and won’t attempt to) reproduce, duplicate, copy, sell, trade or resell any products or services bearing any trade mark, trade name, logo or service mark owned by or licensed to Clever in a way that is likely or intended to cause confusion about the owner or authorised user of such marks, names or logos. If this happens you may be responsible for any losses we incur as a result.
            </p>
          </article>
          <article>
            <h3>
              Keeping you secure
            </h3>
            <p>
              To get the most value from Clever you will need to connect your online personal accounts, such as current accounts and credit cards.
              <br></br>
              We will use technology provided by a secure third party service-provider to access information about the balances on your personal accounts. You will be asked to provide your explicit consent to us accessing this information. We will request your personal account information from your providers in accordance with your explicit consent until the consent expires or is withdrawn.
              <br></br>
              Our service also allows information to be obtained (such as transaction history) from your personal accounts for use with other third parties. If you select this option and provide your explicit consent to us, we will provide the information to you and the relevant third party in line with your instructions. We will request the relevant information from your personal account providers on a regular basis in accordance with your consent until the consent expires or is withdrawn. Clever will not request more information than is necessary to provide the specific service that we are offering, and we will not request information from your personal account providers which could be used to carry out fraud. For example, we may ask for an account number, but we won't ask for any secret passwords, numbers or codes linked to your personal accounts.
              <br></br>
              Clever won’t check the accuracy of the information given by your personal account providers and won’t be able to correct or investigate your personal accounts on behalf of your providers. You should check your personal accounts if you think you might go overdrawn and/or you are not sure if you have enough money to make a payment. We are not responsible for ensuring that you are always aware of your personal account balances, or that the balances we show you are always up to date. We are also not responsible for any action you choose to take as a result of any information we provide.</p>
          </article>
          <article>
            <h3>
              Connecting your personal accounts
            </h3>
            <p>
              To get the most value from Clever you will need to connect your online personal accounts, such as current accounts and credit cards.
              <br></br>
              We will use technology provided by a secure third party service-provider to access information about the balances on your personal accounts. You will be asked to provide your explicit consent to us accessing this information. We will request your personal account information from your providers in accordance with your explicit consent until the consent expires or is withdrawn.
              <br></br>
              Our service also allows information to be obtained (such as transaction history) from your personal accounts for use with other third parties. If you select this option and provide your explicit consent to us, we will provide the information to you and the relevant third party in line with your instructions. We will request the relevant information from your personal account providers on a regular basis in accordance with your consent until the consent expires or is withdrawn. Clever will not request more information than is necessary to provide the specific service that we are offering, and we will not request information from your personal account providers which could be used to carry out fraud. For example, we may ask for an account number, but we won't ask for any secret passwords, numbers or codes linked to your personal accounts.
              <br></br>
              Clever won’t check the accuracy of the information given by your personal account providers and won’t be able to correct or investigate your personal accounts on behalf of your providers. You should check your personal accounts if you think you might go overdrawn and/or you are not sure if you have enough money to make a payment. We are not responsible for ensuring that you are always aware of your personal account balances, or that the balances we show you are always up to date. We are also not responsible for any action you choose to take as a result of any information we provide.
            </p>
          </article>
          <article>
            <h3>
              Close rewards
            </h3>
            <p>
              Sometimes we will offer rewards for things like linking your accounts or inviting friends to join Clever. Unless we have given an expiry date, we can withdraw or change these offers any time, without letting you know in advance.
            </p>
          </article>
          <article>
            <h3>
              Your right to cancel and end this agreement
            </h3>
            <p>
              You have a right to cancel our service without charge at any time in the first 30 days after you have registered with us. You can do this by requesting to close your Clever account by emailing us at <strong><ins><a href="mailto:team@helloclever.co">team@helloclever.co</a></ins></strong> or from within the Clever App itself if that feature is available, and then uninstalling the App.
              <br></br>
              You can also end this agreement at any other time without charge in the Clever App by contacting us.
              <br></br>
              If you cancel or end this agreement, we will no longer use your personal data in the ways laid out in the Privacy Policy. Once a further 12 months have passed, we will anonymise and archive all personal data we hold about you.
            </p>
          </article>
          <article>
            <h3>
              Our right to suspend, restrict or end this agreement
            </h3>
            <p>
              We may stop providing the service if you’ve not used Clever for a while, but we’ll always check with you first before we do. We may also close or freeze your account without your permission if you have been abusive to a member of Clever staff or community member, or submitted inappropriate and/or offensive content.
              <br></br>
              We may at any time suspend, restrict, or end your use of our service, your account or the App if we think your security may have been breached, or your privacy could be at risk through attempted fraudulent activity, including if:
              <br></br>
              We reasonably believe that your security PIN has not been kept safe or the security of your App or the device on which it has been installed has been compromised.
              <br></br>
              We reasonably suspect that your security PIN, the App, or the device on which it has been installed has been used fraudulently or without your permission.
              <br></br>
              We need to do so to comply with the law of any jurisdiction.
              <br></br>
              Unless informing you would compromise reasonable security measures or otherwise be unlawful, we will inform you before suspending, restricting or cancelling our service, your account or the App, and we will tell you our reasons why we’ve done this. If it is not practical to inform you in advance and unless doing so would compromise reasonable security measures or otherwise be unlawful, we will inform you immediately afterwards, giving the reasons why we’ve suspended or cancelled your service. We will allow you to resume your use of the App or the service as soon as practical after the reasons for stopping its use cease to exist.
              <br></br>
              In the event that we need to contact you in respect of suspected fraud or any other security threat we will do so in the way that we think is the most secure in the circumstances.
            </p>
          </article>
          <article>
            <h3>
              Making changes to this agreement
            </h3>
            <p>
              These Terms will always be available in the Clever App and on our website.
              <br></br>
              We can make changes to these Terms from time to time, including introducing fees or charges for certain parts of our service. As this agreement has no fixed duration, we can't predict all of the reasons why we might change these Terms, but we have set out some of these below. We'll only ever make changes that are proportionate and reasonable and if we have a valid reason for doing so, taking into account your interests (even if it is not one of the reasons set out below).
              <br></br>
              We might make changes because:
              <br></br>
              We have made changes to our technology or other systems.
              <br></br>
              There have been changes to legislation, changes in regulatory requirements or new case law that affects us.
              <br></br>
              There have been changes in the cost to us of funding our service.
              <br></br>
              For any other valid reason
              <br></br>
              We will give you at least two months' prior written notice by email or through the App of any intended change to the Terms along with the new version of the Terms. If you don't agree to the changes, you can close your Clever account by emailing us at <strong><ins><a href="mailto:team@helloclever.co">team@helloclever.co</a></ins></strong> or from within the Clever App if that feature is available. If you don’t close your Clever account, we’ll assume that you’re happy with the changes we’ve made. You have a right to end this agreement at any time before the proposed change takes effect.
            </p>
          </article>
          <article>
            <h3>
              How to make a complaint
            </h3>
            <p>
              If you have a complaint, please send us a message from the Clever App or email us at <strong><ins><a href="mailto:team@helloclever.co">team@helloclever.co</a></ins></strong>. We’ll send you a prompt reply and will get back to you with a full response to your complaint as soon as we can, but always within 15 business days of receiving it. We'll do our best to fix the problem.
            </p>
          </article>
          <article>
            <h3>
              Applicable law
            </h3>
            <p>
              These Terms, and all our dealings with you are governed by the laws of NSW.
              <br></br>
              These Terms are provided in English.
            </p>
          </article>
          <article>
            <h3>
              About us
            </h3>
            <p>
              We are Aircrex Pty Limited trading as Hello Clever. Our registered office is at 2/23 Foster Street, Surry Hills, NSW 2010 and our ACN number is <strong>637 950 497.</strong>
              <br></br>
              Disclaimer: The information contained in this article is general in nature and does not take into account your personal objectives, financial situation or needs. Therefore, you should consider whether the information is appropriate to your circumstances before acting on it, and where appropriate, seek professional advice from a finance professional.
              <br></br>
              You can talk to Clever at any time by sending an email at <strong><ins><a href="mailto:team@helloclever.co">team@helloclever.co</a></ins></strong>
            </p>
          </article>
        </div>
      </div>
    </div>
  );
};
export default TermsPage;
