import * as React from "react";
import Layout from "../components/layout";
import { StickyContainer } from "react-sticky";
import TermsPage from "../containers/Terms/TermsPage";

// markup
const Terms = () => {
  return (
    <StickyContainer>
      <Layout>
        <TermsPage />
      </Layout>
    </StickyContainer>
  );
};

export default Terms;
